import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/Sections/Hero'
import { Link } from 'gatsby'
import { getAssetUrl } from '../services/cdn'
import { Container, Row, Col } from 'reactstrap'
import { Container as BlockContainer, Benefit } from '../components/Blocks'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src='app/SIDC-Agriculture-Farmer.jpg' mobileSrc='app/Agriculture_Cover_Mobile.jpg' height={null} x={10} y={0} fullMobile dim={0.5} className="mt-4">
      <h1 className='display-1 pb-5 mt-5 text-white text-center text-md-right'>SIDC AGRICULTURE</h1>
    </Hero>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">SIDC: 55 years of helping farmers</h1>
              <p>For 55 years, the Sorosoro Ibaba Development Cooperative (SIDC) — one of the largest agri-based cooperatives in the country, has been helping farmers and its members to be successfully empowered by increasing their participation in the agricultural value chain and enhancing their access to more lucrative and dynamic markets through collective action.</p>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Benefits of SIDC AGRICULTURE</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6">
              <Benefit src="agriculture/Small-Farmer-Aid.png" iconClass="fa fa-circle fa-3x" title="Small Farmers Aid">SIDC is a key driver for enabling small farmers to take advantage of the economic opportunities associated with them.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="agriculture/Economic-Gain.png" iconClass="fa fa-stop-circle-o fa-3x" title="Economic Gain">Farmers are able to reap more economic gains than they would have if they earned as individual producers.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="agriculture/Contract-Growing-Availer.png" iconClass="fa fa-stop-circle fa-3x" title="Contract Growing Availer">Members can be one of the paiwi system availers, the contract-growing project of SIDC that has brought means of livelihood to members by giving them the opportunity to raise hogs.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="agriculture/Business-Knowledge-Assistance.png" iconClass="fa fa-circle-o fa-3x" title="Business Knowledge Assistance">SIDC gives assistance to its members who wanted to put up a SIDC Feeds outlet by providing them with credit line that will assist them in their livelihood.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="agriculture/Financial-Assistance.png" iconClass="fa fa-circle fa-3x" title="SIDC Financial Assistance">SIDC through its KooPinoy SIDC Savings and Loans venture provides farmers with financial assistance to sustain their financial capacity.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="agriculture/Trainings-and-Seminar.png" iconClass="fa fa-stop-circle-o fa-3x" title="Trainings and Seminar">SIDC provides trainings and financial literacy programs to farmers for their continuous enterprise development.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="agriculture/Agri-Inputs.png" iconClass="fa fa-stop-circle fa-3x" title="Agri-Inputs Availability">SIDC provides agri-inputs to farmers who wanted to increase their agricultural production outputs. Koop Likas organic fertilizer is one on the inputs available.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="agriculture/Technical-Assistance.png" iconClass="fa fa-circle-o fa-3x" title="SIDC Technical Assistance">SIDC provides technical assistance to farmers and producers so they may understand the use of new technology and farming methods.</Benefit>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={3}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">SIDC Agricultural Products and Services are open to the cooperative’s members and non-members</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 1 }} className="p-0">
            <img className="img-fluid d-block py-5 m-auto" alt="SIDC PRODUCTS" src={`${getAssetUrl('agriculture/rice_cl_1.jpg')}`} />
          </Col>
          <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">FEEDMILL</p>
            <div className="mb-0 text-justify">
              <p>SIDC, has its own feed mill, an ISO 9001:2015 certified facility that produces an average of 9,000 bags per day. It uses state-of-the-art machineries for the production of mash, pellet and crumble feeds for swine, poultry, broilers and ruminants.</p>
            </div>
            <div className="pt-3"> <Link to="/about#feedmill" className="btn btn-white border-primary text-primary">Read more</Link> </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">CONTRACT GROWING</p>
            <div className="mb-0 text-justify">
              <p>Known in the locality of Batangas as the “paiwi system”, the contract growing project has brought means of livelihood to its members by giving them the opportunity to raise cattle and hogs.</p>
              <p>Expenses related to growing are born by the cooperative as well as the marketing of the products. The cooperative and the member-raisers will share profits equally.</p>
            </div>
            <div className="pt-3"> <Link to="/about#contract-growing" className="btn btn-white border-primary text-primary">Read more</Link> </div>
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 2 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('agriculture/Contract Growing pic-1.jpg')}`} />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 1 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('agriculture/Layer-Farm-4.jpg')}`} />
          </Col>
          <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">LAYER FARM</p>
            <div className="mb-0 text-justify">
              <p>Another project of the Cooperative located at Brgy. Balanga, Ibaan Batangas and Brgy Anus, San Jose Batangas that provides regular supply of egg to the members and other customers.</p>
            </div>
            <div className="pt-3"> <Link to="/about#layer-farm" className="btn btn-white border-primary text-primary">Read more</Link> </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">RICEMILL</p>
            <div className="mb-0 text-justify">
              <p>Due to the increasing demand of Gintong Gawad quality rice, the cooperative opened its state-of-the-art Rice Mill in Naujan, Oriental Mindoro last November 18, 2021 which produces 1600 bags of rice (in 25kg) per day. This facility replaced the old  rice mill Brgy. Matungao, Socorro, Oriental Mindoro, which started its operation in 2002 and produces 220 sacks of rice a day.</p>
            </div>
            <div className="pt-3"> <Link to="/about#ricemill" className="btn btn-white border-primary text-primary">Read more</Link> </div>
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 2 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('agriculture/Rice-Mill-6.jpg')}`} />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 1 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('agriculture/KoopLikas-4.jpg')}`} />
          </Col>
          <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">KOOPLIKAS ORGANIC FERTILIZER</p>
            <div className="mb-0 text-justify">
              <p>An OCCP Certified organic fertilizer made from various safe and natural ingredients like chicken dung and hog manure.</p>
              <p>SIDC manufactures this organic fertilizer for rice, corn, ornamentals and vegetable crops.</p>
            </div>
            <div className="pt-3"> <Link to="/about#kooplikas" className="btn btn-white border-primary text-primary">Read more</Link> </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">SIDC Vet Products</p>
            <div className="mb-0 text-justify">
              <p>Quality and affordable farm-tested Antibiotics and Vitamins for Hogs & Poultry. A reliable livestock raiser’s partner for a more profitable livestock production business.</p>
            </div>
            <div className="pt-3"> <Link to="/about#rightvet" className="btn btn-white border-primary text-primary">Read more</Link> </div>
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 2 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('agriculture/SIDC-VetProduct.jpg')}`} />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
  </Layout>
)

export default IndexPage
